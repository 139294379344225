<template>
  <div>
    <table
      class="table table-sm"
      :class="{ 'table-responsive': $store.state.display.sm }"
    >
      <thead>
        <tr class="text-center">
          <th>Tratamiento</th>
          <th>Pieza</th>
          <!-- <th class="text-right">-%</th>
          <th class="text-right">Precio</th>-->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in details" :key="l.id">
          <td>{{ l.product_name }}</td>
          <td class="text-center">
            <ToothPiece
              v-for="l1 in l.th_list"
              :key="'m' + l1"
              :piece="l1"
            ></ToothPiece>
          </td>
          <!-- <td class="text-right">
            <app-span-input
              :disabled="l.paid_out"
              :value="l.discount"
              @input="setDiscount(l, $event)"
              :sm="true"
            >{{l.discount}}%</app-span-input>
          </td>-->
          <!-- <td class="text-right">
            <app-span-input
              :disabled="l.paid_out"
              :value="l.unit_price"
              @input="setPriceDetail(l, $event)"
              :sm="true"
            >
              <app-span-money :quantity="l.unit_price" :moneyCode="money_code"></app-span-money>
            </app-span-input>
          </td>-->
          <td>
            <button
              class="btn btn-light btn-sm"
              @click="
                $refs.dDelDet.show();
                $refs.dDelDet.val = l.id;
              "
              v-show="!disabled"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
        <tr v-if="total">
          <td></td>
          <td></td>
          <!-- <td></td> -->
          <!-- <th class="text-right">
            <app-span-money :quantity="total" :moneyCode="money_code"></app-span-money>
          </th>-->
        </tr>
      </tbody>
    </table>

    <app-modal-yn
      ref="dDelDet"
      @yes="deleteDetail($refs.dDelDet.val)"
      @no="$refs.dDelDet.hide()"
    >
      <p v-if="$refs.dDelDet && $refs.dDelDet.val">
        Seguro de eliminar el tratamiento?
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import ToothPiece from "../odontogram/ToothPiece";

export default {
  components: {
    ToothPiece
  },
  props: {
    treatment_plan_id: {},
    money_code: { default: 1 },
    disabled: { default: false },
    proform_id: {}
  },
  computed: {
    total() {
      let total = 0;
      this.details.map(x => {
        total += parseFloat(x.unit_price);
      });
      return total;
    }
  },
  watch: {
    treatment_plan_id: {
      immediate: true,
      handler() {
        this.getDetails();
      }
    }
  },
  data: () => ({
    details: []
  }),
  methods: {
    setPriceDetail(l, price) {
      DentalClinicService.setPriceTreatmentDetail(l.id, price).then(() => {
        this.getDetails();
      });
    },
    setDiscount(l, discount) {
      DentalClinicService.setDiscountTreatmentDetail(l.id, discount).then(
        () => {
          this.getDetails();
        }
      );
    },
    deleteDetail(detail_id) {
      DentalClinicService
        .deleteProformDetail(detail_id)
        // .deleteTreatmentPlanDetail(detail_id)
        .then(() => {
        this.getDetails();
        this.$refs.dDelDet.hide();
        this.$emit("detailDeleted");
      });
    },
    reload() {
      this.getDetails();
    },
    getDetails() {
      DentalClinicService.getTreatmentPlanDetails({
        treatment_plan_id: this.treatment_plan_id,
        proform_id: this.proform_id
      }).then(list => {
        this.details = list;
      });
    }
  }
};
</script>

<style></style>
