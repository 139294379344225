<template>
  <FormPage ref="formPage" @createProform="afterSubmit($event)"></FormPage>
</template>
<script>
import FormPage from "./FormPage.vue";

export default {
  components: {
    FormPage
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    if (this.$route.query.g) {
      this.$refs.formPage.setGroup(this.$route.query.g);
    }
  },

  methods: {
    afterSubmit(e) {
      this.$router.push("/dental-clinic/proforms/" + e);
    }
  }
};
</script>

<style></style>
