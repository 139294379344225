<template>
  <div class="row">
    <div class="col">
      <h4 v-show="group != 4">PROFORMA {{ group_name }}</h4>
      <h4 v-show="group == 4">PROSPECTO ORTODONCIA</h4>
      <div class="row">
        <div class="col-md-4">
          <FormCreate
            ref="formCreate"
            :disabled="disabled"
            :group="group"
            @submitted="$emit('createProform', $event)"
          ></FormCreate>
          <div class="d-print-none form-group" v-show="!used">
            <app-button-submit
              text="Iniciar Tratamiento"
              v-show="proform_id"
              @click="$refs.qBegins.show()"
            ></app-button-submit>
          </div>
          <div class="d-print-none">
            <router-link :to="toTreatment"
              ><i class="fa fa-link"></i> Ver Tratamiento</router-link
            >
            <h5>Comentarios</h5>
            <app-comments :url="`proform_id_${proform_id}`"></app-comments>
          </div>
        </div>

        <div class="col">
          <div
            v-if="mountOdontogram"
            v-show="group != 4 && !!proform_id"
            ref="odontogramContainer"
          >
            <div class="d-print-none mb-2" v-show="proform_id && !used">
              <!-- <app-button-load class="btn-sm btn-secondary" @click="$refs.dDiscount.show()">
                <i class="fas fa-percent"></i> Descuento
              </app-button-load> -->
              <!-- <app-button-load
                v-for="(l, index) in config.sugest_products"
                :key="'sugst' + index"
                class="btn btn-light btn-sm mr-1 mb-1 shadow-sm"
                @click.prevent="addProductById(l.product_id, l.status)"
                v-show="l.group == undefined || group == l.group"
              >
                <i class="fa fa-plus"></i>
                {{ l.name }}
              </app-button-load> -->
            </div>
            <Odontogram
              @mounted="afterInstanceOdontogram()"
              @detailAdded="$refs.details.getDetails()"
              :treatment_plan_id="treatment_plan_id"
              ref="odontogram"
              :proform_id="proform_id"
              :disabled="used"
              :group="group"
            ></Odontogram>
            <Details
              @detailDeleted="$refs.odontogram.reload()"
              ref="details"
              :money_code="money_code"
              :disabled="used"
              :treatment_plan_id="treatment_plan_id"
              :proform_id="proform_id"
            ></Details>
          </div>
          <div
            v-if="mountInitialOrt"
            v-show="group == 4 && !!proform_id"
            ref="initialOrtContainer"
          >
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  client_id +
                  '/treatment-plans/' +
                  treatment_plan_id
              "
              class="btn btn-secondary mr-1"
            >
              <i class="fas fa-notes-medical"></i> Tratamiento
            </router-link>
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  client_id +
                  '/orthodontics/' +
                  treatment_plan_id +
                  '/plan'
              "
              class="btn btn-secondary mr-1"
            >
              <i class="fas fa-id-card-alt"></i> Plan de tratamiento
            </router-link>
            <Initial
              @mounted="$refs.ortInitial.reload()"
              class="mt-2"
              ref="ortInitial"
              :client_id="client_id"
              :treatment_plan_id="treatment_plan_id"
              :proform_id="proform_id"
            ></Initial>
          </div>
        </div>
      </div>
    </div>

    <app-modal-yn ref="qBegins" @yes="beginTreatment()">
      <p>Iniciar tratamiento?</p>
    </app-modal-yn>

    <DialogDiscount
      :treatment_plan_id="treatment_plan_id"
      ref="dDiscount"
    ></DialogDiscount>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormCreate from "./FormCreate.vue";
import Details from "./Details";
import { DentalClinicService } from "../service";
import Odontogram from "./Odontogram.vue";
// import { mapMutations } from "vuex";
import Initial from "../orthodontics/Initial";
import Vue from "vue";
import DialogDiscount from "../treatments/DialogDiscount";

export default {
  components: {
    FormCreate,
    Details,
    Odontogram,
    Initial,
    DialogDiscount
  },
  props: {
    proform_id: {}
  },
  mounted() {
    if (this.proform_id) {
      DentalClinicService.getProform(this.proform_id).then(res => {
        this.$refs.formCreate.load(res);
        this.disabled = !!res.used;
        this.group = res.group;
        // this.setOdoGroup(res.group);
        // this.odontogramGroup = res.group;
        this.odontogram_id = res.odontogram_id;
        this.treatment_plan_id = res.treatment_plan_id;
        this.money_code = res.money_code;
        this.client_id = res.client_id;
        this.name = res.odontogram.name;
        this.used = res.used;

        if (res.group != 4) {
          // this.instanceOdontogram();
          this.mountOdontogram = true;
          // this.$forceUpdate();

          // this.$refs.odontogram.setParams({
          //   client_id: res.odontogram.client_id,
          //   name: res.odontogram.name,
          //   odontogram_id: this.odontogram_id
          // });
        } else {
          // this.instanceOrthodontic();
          this.mountInitialOrt = true;
          // this.$forceUpdate();
        }
      });
    }
  },
  data: () => ({
    used: null,
    name: null,
    group: null,
    disabled: false,
    odontogram_id: null,
    treatment_plan_id: null,
    money_code: null,
    client_id: null,
    mountOdontogram: false,
    mountInitialOrt: false
  }),
  computed: {
    toTreatment() {
      if (this.used) {
        return this.getLinkTotreatmentPlan(true);
      } else return this.getLinkTotreatmentPlan();
    },
    ...mapState({
      config: s => s.config.dental.proform,
      odontogram: s => s.config.dental.odontogram
    }),
    group_name() {
      return this.group ? this.odontogram.groups[this.group] : "";
    }
  },
  methods: {
    afterInstanceOdontogram() {
      this.$refs.odontogram.setParams({
        client_id: this.client_id,
        name: this.name,
        odontogram_id: this.odontogram_id
      });
    },
    setGroup(group) {
      this.group = group;
    },
    instanceOrthodontic() {
      var InitialContainer = Vue.extend(Initial);
      var initialInstance = new InitialContainer({
        // treatment_plan_id: this.treatment_plan_id,
        store: this.$store
      });
      initialInstance.$mount();
      initialInstance.treatment_plan_id = this.treatment_plan_id;
      this.$refs.initialOrtContainer.appendChild(initialInstance.$el);
    },
    instanceOdontogram() {
      var OdontogramComponent = Vue.extend(Odontogram);
      var odontogramInstance = new OdontogramComponent({
        propsData: {
          treatment_plan_id: this.treatment_plan_id
        },
        store: this.$store
      });
      odontogramInstance.$mount();
      this.$refs.odontogramContainer.appendChild(odontogramInstance.$el);
      this.$refs.odontogram = odontogramInstance;

      var DetailsComponent = Vue.extend(Details);
      var detailsInstance = new DetailsComponent({
        propsData: {
          treatment_plan_id: this.treatment_plan_id,
          money_code: this.money_code
        },
        store: this.$store
      });
      detailsInstance.$mount();
      this.$refs.odontogramContainer.appendChild(detailsInstance.$el);
      this.$refs.details = detailsInstance;

      detailsInstance.getDetails();

      odontogramInstance.$on("detailAdded", () => {
        detailsInstance.getDetails();
      });
      detailsInstance.$on("detailDeleted", () => {
        odontogramInstance.reload();
      });
    },
    getLinkTotreatmentPlan(noProform = false) {
      return `/dental-clinic/patients/${this.client_id}/treatment-plans/${
        this.treatment_plan_id
      }${!noProform ? "?proform_id=" + this.proform_id : ""}`;
    },
    beginTreatment() {
      DentalClinicService.saveTreatmentPlan({
        proform_id: this.proform_id
      }).then(() => {
        this.$router.push(this.getLinkTotreatmentPlan(true));
      });
    },
    async addProductById(product_id, status) {
      let toothState = undefined;
      if (status !== undefined) {
        toothState = await DentalClinicService.saveToothStatus({
          piece: 0,
          status,
          product_id,
          name: this.name,
          client_id: this.client_id
        });
      }

      var detail = {};
      detail.treatment_plan_id = this.treatment_plan_id;
      detail.product_id = product_id;
      detail.maked = false;
      detail.budgeted = true;
      detail.proform_id = this.proform_id;
      if (toothState) detail.teeth_history_id = toothState.id;
      DentalClinicService.saveTreatmentPlanDetail(detail).then(() => {
        this.$refs.details.reload();
      });
    }
  }
};
</script>

<style></style>
