<template>
  <div>
    <div class="dropdown d-print-none">
      <button
        class="btn btn-sm btn-light dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fa fa-cog"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="#" @click.prevent="seeOdontogram = 1">
          <i class="fas fa-teeth"></i> Odontograma
        </a>
        <a class="dropdown-item" href="#" @click.prevent="seeOdontogram = 2">
          <i class="fas fa-notes-medical"></i> Odontograma Comercial
        </a>
      </div>
    </div>
    <Odontogram
      v-show="seeOdontogram == 1"
      @statusAdded="addFromTeethHistory($event)"
      @mode2Added="addFromTeethList($event)"
      ref="odontogram"
      :canSetName="false"
      :disabled="disabled"
      :seeTeethHistory="seeTeethHistory"
      :group="group"
    >
      <app-checkbox
        v-model="seeTeethHistory"
        placeholder="Ver historial de dientes"
      ></app-checkbox>
    </Odontogram>
    <div v-show="seeOdontogram == 2" class="d-print-none">
      <ComOdontogram
        @submitted="
          $emit('detailAdded');
          $refs.com_odontogram.reset();
        "
        ref="com_odontogram"
        :money_code="money_code"
        :treatment_plan_id="treatment_plan_id"
        :proform_id="proform_id"
      ></ComOdontogram>
    </div>
  </div>
</template>

<script>
import Odontogram from "../odontogram/Odontogram";
import ComOdontogram from "../treatments/ComOdoToTreatment.vue";
// import SelectProduct from "../../store-module/products/Select";
import { DentalClinicService } from "../service";
// import { ProformDetailModel } from "../models";
// import { mapState } from "vuex";

export default {
  components: {
    Odontogram,
    ComOdontogram
    // SelectProduct
    // SelectCategorie
  },
  computed: {
    // ...mapState({
    //   group: s => s.dental.odo_group
    // })
  },
  data: () => ({
    seeOdontogram: 1,
    categorie_id: null,
    product_id: null,
    seeTeethHistory: false
  }),
  props: {
    odontogram_id: {},
    disabled: {},
    treatment_plan_id: {},
    money_code: {},
    proform_id: {},
    group: { default: 1 }
  },
  mounted() {
    this.$emit("mounted");
  },
  methods: {
    reload() {
      this.$refs.odontogram.reload();
    },
    setParams({ client_id, name }) {
      this.$refs.odontogram.setClientId(client_id);
      this.$refs.odontogram.setName(name);
      // this.$refs.odontogram.setOdontogramId(odontogram_id);
      this.$refs.odontogram.setFlagColor(1); //rojo
      this.$refs.odontogram.reload();
      this.$refs.odontogram.setSeeTempTeeth(this.group == 2);
    },
    addFromTeethHistory(teethHistory) {
      let detail = {};
      detail.product_id = teethHistory.product_id;
      detail.treatment_plan_id = this.treatment_plan_id;
      detail.unit_price = teethHistory.product_unit_price;
      detail.teeth_history_id = teethHistory.id;
      detail.comment = teethHistory.comment;
      detail.maked = false;
      detail.budgeted = true;
      detail.proform_id = this.proform_id;
      DentalClinicService.saveTreatmentPlanDetail(detail).then(() =>
        this.$emit("detailAdded")
      );
    },

    addFromTeethList(teethList = []) {
      var teethHistory = teethList[0];

      let detail = {};
      detail.product_id = teethHistory.product_id;
      detail.treatment_plan_id = this.treatment_plan_id;
      detail.unit_price = teethHistory.product_unit_price;
      // detail.teeth_history_id = teethHistory.id;

      detail.teeth_history_list = teethList.map(x => x.id);

      detail.comment = teethHistory.comment;
      detail.maked = false;
      detail.budgeted = true;
      detail.proform_id = this.proform_id;

      DentalClinicService.saveTreatmentPlanDetail(detail).then(() =>
        this.$emit("detailAdded")
      );
    }
  }
};
</script>

<style></style>
